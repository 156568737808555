import React from "react";
// Components
import PagesMain from "components/Pages/Main";
// Locales EN
import metatagsLocale from "locales/en/metatags.json";
import navbarLocale from "locales/en/navbar.json";
import headerLocale from "locales/en/header.json";
import introLocale from "locales/en/intro.json";
import featuresLocale from "locales/en/features.json";
import portfolioLocale from "locales/en/portfolio.json";
import pricingLocale from "locales/en/pricing.json";
import linksLocale from "locales/en/links.json";

const locales = {
  metatagsLocale,
  navbarLocale,
  headerLocale,
  introLocale,
  featuresLocale,
  portfolioLocale,
  pricingLocale,
  linksLocale
};

const IndexPage = () => <PagesMain locales={locales} />;

export default IndexPage;
